/******************************************************************************
START Glitch hello-app default styles

The styles in this section do some minimal CSS resets, set default fonts and 
colors, and handle the layout for our footer and "Remix on Glitch" button. If
you're new to CSS they may seem a little complicated, but you can scroll down
to this section's matching END comment to see page-specific styles.
******************************************************************************/
/* Our default values set as CSS variables */
:root {
  --color-bg: #FAF4EE;
  --color-text-main: #000000;
  --color-primary: #AFECE0;
  --wrapper-height: 85vh;
  --image-max-width: 320px;
  --font-family: "HK Grotesk";
  --font-family-header: "HK Grotesk";
}

/* Basic page style resets */
* {
  box-sizing: border-box;
}
[hidden] {
  display: none !important;
}

/* Import fonts */
@font-face {
  font-family: HK Grotesk;
  src: url("https://cdn.glitch.com/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Regular.otf?v=1603136326027")
    format("opentype");
}
@font-face {
  font-family: HK Grotesk;
  font-weight: bold;
  src: url("https://cdn.glitch.com/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Bold.otf?v=1603136323437")
    format("opentype");
}
@import url('https://fonts.googleapis.com/css2?family=Noticia+Text:wght@700&family=Orelega+One&family=Playfair+Display:ital,wght@0,400;0,800;0,900;1,800;1,900&Libre+Franklin:ital,wght@0,200;0,400;0,600;0,900;1,400;1,600;1,900&display=swap');

/* Our remix on glitch button */
.btn--remix {
  font-family: HK Grotesk;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  line-height: 1rem;
  font-weight: 500;
  height: 2.75rem;
  align-items: center;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  color: #000;
  white-space: nowrap;
  margin-left: auto;
}
.btn--remix img {
  margin-right: 0.5rem;
}
.btn--remix:hover {
  background-color: #D0FFF1;
}

/* Navigation grid */
.footer {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto 0;
  padding: 1rem 0 0.75rem 0;
  width: 100%;
  flex-wrap: wrap;
  border-top: 4px solid #fff;
}

.footer a:not(.btn--remix):link,
a:not(.btn--remix):visited {
  font-family: HK Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: #000;
  text-decoration: none;
  border-style: none;
}
.footer a:hover {
  background: var(--color-primary);
}

.footer .links {
  padding: 0.5rem 1rem 1.5rem;
  white-space: nowrap;
}

.divider {
  padding: 0 1rem;
}
/******************************************************************************
END Glitch hello-app default styles
******************************************************************************/

/* Page structure */

body {
  font-family: 'Libre Franklin', sans-serif;
  background-color: #FAF4EE !important;
}
.wrapper {
  min-height: var(--wrapper-height);
  display: grid;
  place-items: center;
  margin: 0 1rem;
  min-height: 86vh;
}


/* Page titles */
h1 {
  color: #0C0908 !important;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
  line-height: 105%;
  padding-top:1em;
}

h3 {
  color: #FAF4EE !important;
  background-color: #0C0908 !important;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: bold;
  line-height: 105%;
  padding:.5em !important;
}
.page h1 {
  font-size: 48px;
}

.page p {
  line-height: 1.5;
}

a {
  color: #427599 !important;

}

a:hover {
  text-decoration:underline !important;}

/* The React image and "click me" text */
.btn--click-me {
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}
.btn--click-me:hover {
  text-decoration: underline;
}

/* Very light scaling for our illustration */
.illustration {
  max-width: 100%;
  max-height: var(--image-max-width);
  cursor: pointer;
  padding-left: 3.5rem;
}

img.railImg {
  width:300px;
}

img.smallImg {
  width:100%;
  max-width:400px;
  padding:1em;
  vertical-align: middle;
  margin:0 auto !important;

}

.btn-danger {
    color: #0C0908 !important;
    background-color: #fcd232 !important;
    border-color: #fcd232 !important;
  font-family:'Libre Franklin',Arial, Helvetica, sans-serif;
  font-weight:700 !important;
}

.imgpad {padding:1em 0;}

.subhead {
  font-weight:bold;
}

a.linkbutton {
 color:#0C0908 !important;
 font-size:1.5em; 
 font-family:'Libre Franklin',Arial, Helvetica, sans-serif;
}